import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../styles/Tabs.css';
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import '../styles/Pie.css';


function pie() {
  return (
    <Container fluid>
      <Row>
        <Col xs={4} md={4}>
            
        </Col>
        <Col xs={4} md={4} className='text-center'>
            <a href="https://www.facebook.com/catsaconcretos.mx" target="_blank" rel="noopener noreferrer">
            <FaFacebook size={42} className="icon-facebook"/>
            </a>
            <a href="link de la pagina" target="_blank" rel="noopener noreferrer">
            <FaInstagram size={42} className="icon-instagram"/>  
            </a>
            <a href="https://www.linkedin.com/company/catsa-concretos-de-alta-tecnología/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin size={42} className="icon-linkedin"/>  
            </a>
        </Col>
        <Col xs={4} md={4}>
            
        </Col>
      </Row>
      <Row>
        <col></col>
        <col></col>
        <col></col>
      </Row>
      <Row>
        <div className='text-center'>
          <a href="/views/AvisoPrivacidad" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
            AVISO DE PRIVACIDAD
          </a>        
        </div>
      </Row>
      <Row>
        <div className='text-center'>
            Copyright © CATSA 2024 Todos los derechos reservados.
        </div>
      </Row>
    </Container>
  );
}

export default pie;