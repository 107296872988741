import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import 'bootstrap/dist/css/bootstrap.min.css';
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import Bar from './views/barra';
import Tabs from './views/Tabs';
import Ainicio from './views/Ainicio';
import Carru from './views/Carru';
import Contacto from './views/Contacto';
import Mapa from './views/mapa';
import Pie from './views/pie';
import Panel from './views/panel';
import Letras from './views/letras';
import AvisoPrivacidad from './views/AvisoPrivacidad';
import FondoImagen from '../src/images/Inicio/Pagina-01-01.jpg'




function App() {

  const sectionStyle = {
    backgroundImage: `url(${FondoImagen})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <Router>
      <ThemeProvider
        breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
        minBreakpoint="xxs">
        
        <Routes>
          <Route path="/views/AvisoPrivacidad" element={<AvisoPrivacidad />} />
          
          <Route path="/" element={
            <>
              <Bar />
              <Row id="inicio" style={sectionStyle}>
                <Ainicio/>
              </Row>
              <Row style={{margin: '8%' }}>
                <div className='text-center' style={{ fontSize: '1.5rem' }}>
                  En <span style={{ color: '#e62a43' }}>CATSA</span>, además de encontrar el mejor concreto; también encuentras el camino hacia tus sueños. La calidad de nuestros productos, construyen y son fuente de <span style={{ color: '#e62a43' }}>Cimientos Fuertes, Soluciones Reales y Futuros Brillantes.</span>
                </div>
              </Row>
              <Row style={{ margin: '5%' }}id="sobre-nosotros">  
                <Panel />
              </Row>
              <Row style={{ paddingTop: '5%' }} id="productos">
                <Tabs/>
              </Row>
              <Row style={{ paddingTop: '20%' }}>
                <Carru />
              </Row>
              <Container>
                <Row style={{ paddingTop: '10%' }} id="clientes">
                  <div className='text-center' style={{ fontSize: '1.4rem' }}>
                    Miles de profesionales de la construcción confían en nosotros para la realización de los proyectos <span style={{ color: '#e62a43' }}>más desafiantes.</span> Así como ellos, <span style={{ color: '#e62a43' }}>únete a la Alta Tecnología</span> y comprueba porque nuestros valores agregados, <span style={{ color: '#e62a43' }}>hacen la diferencia.</span>
                  </div>
                </Row>        
              </Container>
              <Row>
                <Mapa />
              </Row>
              <Row style={{ paddingTop: '7%' }} id="contacto">
                <Contacto />
              </Row>
              <Row style={{ paddingTop: '15%', background: 'linear-gradient(0deg, rgba(230,42,67,1) 0%, rgba(31,35,74,1) 100%)' }}>
                <Letras />
                <Pie />
              </Row>
            </>
          } />
        </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default App;
