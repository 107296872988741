import React from "react";
import styles from '../styles/AvisoPrivacidad.module.css';

const AvisoPrivacidad = () => {
  return (
    <div>
      <div className={styles.header}>
        <h1>AVISO DE PRIVACIDAD</h1>
      </div>

      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.container}>
            <h3>CONCRETOS DE ALTA TECNOLOGIA S. A. DE C. V.</h3>
            <p>
              Con domicilio en Km. 9 carretera federal puebla Atlixco,
              número 8703, San Bernardino, Tlaxcalancingo, Puebla. (en adelante
              "CATSA"), hace de su conocimiento que los datos personales de usted,
              incluyendo los sensibles, que actualmente o en el futuro obren en
              nuestras bases de datos, serán tratados y/o utilizados por CATSA por
              tal motivo y a fin de que conozca las prácticas de CATSA al obtener,
              usar, divulgar o almacenar, sus datos personales; los cuales serán
              tratados únicamente por el tiempo que sea necesario para cumplir con
              las finalidades descritas en el presente Aviso de Privacidad y/o de
              conformidad con lo que establezcan las disposiciones legales
              aplicables.
            </p>
            <h3>FINALIDADES DEL TRATAMIENTO DE DATOS PERSONALES</h3>
            <p>
              Fines comerciales; Los datos personales que recaba CATSA son
              utilizados para cumplir con las obligaciones derivadas de su
              actividad comercial. Sus datos personales pueden ser utilizados para
              la creación y administración de su cuenta; el desarrollo de nuevos
              productos y servicios; conocer el mercado y el desempeño de la
              empresa dentro de este (inteligencia de mercado); dar seguimiento a
              las ventas a fin de alcanzar la satisfacción del cliente; cobranza y
              procesamiento de pagos; contratación de seguros; protección contra
              posibles transacciones fraudulentas o identificación de las mismas;
              creación y gestión de una cartera de clientes, incluida la venta de
              cartera de clientes; servir como garantía en el otorgamiento de
              créditos a favor de CATSA; interpelación de bases de datos para
              conocer el perfil y las necesidades del cliente; envío de publicidad
              y promociones sobre los productos o servicios de CATSA, de acuerdo a
              lo que considere son los intereses del cliente; análisis a fin de
              determinar la eficacia de nuestra publicidad y promociones; análisis
              y estudios crediticios, entrega de productos y cumplimiento de
              obligaciones con nuestros clientes; reclutamiento y selección de
              personal. CATSA podrá utilizar sus datos personales para otros
              fines cuando dichas finalidades sean compatibles con las antes
              descritas y puedan considerarse análogas.
            </p>
            <h3>LIMITACIÓN DEL USO O DIVULGACIÓN DE TUS DATOS PERSONALES</h3>
            <p>
              Acciones para prevenir el uso o divulgación no autorizada. Con el fin
              de garantizar la protección de tus Datos Personales y limitar el uso
              o divulgación no autorizada de los mismos, CATSA continuamente realiza
              y realizará las siguientes acciones: CATSA guardará confidencialidad
              respecto de tus Datos Personales recabados, misma que subsistirá aun
              después de finalizar sus relaciones comerciales o de otra naturaleza
              con el cliente o titular de dichos Datos Personales.
            </p>
            <p>
                Cuando CATSA comparta sus datos personales con terceros se asegurara
                de que dichos terceros tomen las medidas necesarias para proteger Ia
                confidencialidad y seguridad de sus datos personales, así como que
                cumplan con el Aviso de Privacidad y las Políticas de CATSA o, en su
                caso, que le den a conocer el nuevo Aviso de Privacidad CATSA ha
                adoptado las medidas de seguridad, administrativas, técnicas y
                físicas, necesarias para proteger sus datos personales contra daño,
                pérdida, alteración, destrucción o el uso, acceso o tratamiento no
                autorizado. Los Datos Personales son administrados y resguardados
                únicamente por las personas designadas por CATSA para tal efecto, sin
                que se permita su uso, consulta, manejo o acceso a personas no
                autorizadas.
              </p>
              <h3>SOLICITUDES PARA LIMITAR EL USO O DIVULGACIÓN</h3>
              <p>
                En caso que desees limitar el uso o divulgación de tus Datos
                Personales, en relación con una o varias de las Finalidades del
                Tratamiento de Datos Personales podrás enviar la solicitud respectiva
                a CATSA.
              </p>
              <h3>TRANSFERENCIA DE SUS DATOS PERSONALES</h3>
              <p>
                CATSA podrá transferir los datos personales que obren en sus bases de
                datos a cualesquiera de sus empresas filiales, afiliadas o subsidiarias
                y/o subsidiarias e incluso a terceras personas, nacionales o
                extranjeras, a asesores profesionales externos (abogados, contadores,
                auditores) que actúen a nombre y por cuenta de CATSA; prestadores de
                servicios que brinden servicios relacionados con soporte técnico,
                satisfacción al cliente, área de calidad de productos, área de
                servicios, financiamiento y, en general, cualquier tercero que actúe
                a nombre y por cuenta de CATSA; a terceros que no guardan relación
                con CATSA, por ejemplo: aseguradoras e instituciones financieras;
                cuando se pretende contratar un seguro, o cuando se otorgue Ia
                garantía sobre Ia cartera de clientes de CATSA o cuando se busque
                ceder Ia cartera de clientes de CATSA. salvo que los titulares
                respectivos manifiesten expresamente su oposición, por escrito, en
                términos de lo dispuesto por la Ley Federal de Protección de Datos
                Personales en Posesión de los Particulares (la Ley).
              </p>
              <h3>DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN</h3>
              <p>
                Usted, como titular de datos personales, podrá ejercitar ante el
                Departamento de Sistemas de CATSA, los derechos de acceso,
                rectificación, cancelación y oposición establecidos por Ia Ley Federal
                de Protección de Datos Personales en Posesión de los Particulares.
                Existen casos en los que Ia cancelación no será posible, en términos
                de dicha ley y otras disposiciones legales aplicables.
              </p>
              <p>
                El Departamento de Sistemas de CATSA, el cual dará trámite a tus
                solicitudes y fomentará la protección de los Datos Personales. Para lo
                anterior, el Departamento de Datos Personales pone a tu disposición
                las siguientes formas de contacto y recepción de información y
                documentación relacionada: al Correo electrónico:
                aviso.privacidad@catsaconcretos.com
              </p>
              <p>
                Los titulares podrán ejercer los derechos que les confiere la Ley,
                mediante una solicitud por escrito, que deberá ser presentada en el
                domicilio ubicado en Km. 9 carretera federal puebla Atlixco, número
                8703, San Bernardino Tlaxcalancingo, Puebla. Todas las solicitudes que
                sean presentadas a CATSA, deberán de contener: nombre y firma autógrafa
                del titular, así como un domicilio u otro medio para comunicarle la
                respuesta a su solicitud; Acompañar los documentos oficiales que
                acrediten la identidad del titular. Incluir una descripción clara y
                precisa de los datos personales respecto de los cuales ejercitará los
                derechos que les confiere la Ley. Incluir cualquier elemento o
                documento que facilite Ia localización de los datos personales de que
                se traten.
              </p>
              <p>
                CATSA se reserva el derecho de cambiar, modificar, complementar y/o
                alterar el presente Aviso, en cualquier momento, en cuyo caso se hará
                de su conocimiento a través de cualquiera de los medios que establece
                la legislación en la materia.
              </p>
              <p>
                Con la presente solicitud, acepto que he leído y entendido los
                términos y condiciones del Aviso de Privacidad de CATSA, así como
                estoy de acuerdo que los datos personales que actualmente o en un
                futuro obren en nuestras bases de datos, serán tratados y/o
                utilizados por CATSA por tal motivo y a fin de que conozca las
                prácticas de CATSA al obtener, usar, divulgar o almacenar, sus datos
                personales; los cuales serán tratados únicamente por el tiempo que sea
                necesario para cumplir con las finalidades descritas en el presente
                Aviso de Privacidad y/o de conformidad con lo que establezcan las
                disposiciones legales aplicables.
              </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvisoPrivacidad;
