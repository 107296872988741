import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Estilos básicos de Swiper
import 'swiper/css/effect-cube'; // Estilos para el efecto Cube
import 'swiper/css/autoplay'; // Estilos para autoplay
//import '../styles/StyleSwiper.css'; // Estilos personalizados
import '../styles/Animacion.css';

import Cuadro1 from '../images/Inicio/Cuadro3-min.JPG';
import Cuadro2 from '../images/Inicio/Cuadro2-min.jpeg';
import Cuadro4 from '../images/Inicio/Cuadro4-min.JPG';
import Cuadro5 from '../images/Inicio/Cuadro5-min.jpeg';   //221 183 56 52


import { Autoplay, EffectCube } from 'swiper/modules';


const SwiperComponent = () => {

  const handleWhatsAppClick = () => {
    const phoneNumber = "+5212211835652"; // Número de teléfono en formato internacional
    const url = `https://wa.me/${2211835652}`;
    window.open(url, '_blank'); // Abre el enlace en una nueva pestaña
  }
  
  return (
    <section>
      <div className="content">
        <h1>Bienvenido a CATSA Concretos</h1>
        <p>
          Donde la calidad y la excelencia se combinan con la tecnología de vanguarida
          para ofrecerte lo mejor en concreto premezclado. Con más de 20 años de experiencia,
          nos enorgullece cumplir con los más altos estándares y ajustarnos a la necesidades
          específicas de tus proyectos. Confía en nosotros, como lo hacen los expertos.
          ¡Estamos aquí para construir contigo!
        </p>
        <button onClick={handleWhatsAppClick}>WhatsApp</button>
      </div>
      <Swiper
        effect={'cube'}
        grabCursor={true}
        loop={true}
        speed={1000}
        autoplay={{
          delay: 2600,
          pauseOnMouseEnter: true,
        }}
        cubeEffect={{
          shadow: false,
          slideShadows: true,
          shadowOffset: 10,
          shadowScale: 0.94,
        }}
        modules={[Autoplay, EffectCube]}
        className="swiper"
      >
        <SwiperSlide>
        <img src={Cuadro1} alt="Cuadro3" />
          <div className="cost">Perote - Ver</div>
          <div className="overlay">
            <h1>Planta fotovoltaica</h1>
            <p>
              Te ofrecemos la mejor gama de productos en concreto premezclado, cumpliendo
              exactamente con los requerimientos que necesitas para tus proyectos.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <img src={Cuadro2} alt="Cuadro2" />
          <div className="cost">Puebla - Pue</div>
          <div className="overlay">
            <h1>Tiendas innovasport, e innvictus</h1>
            <p>
              Estas en el sitio donde la calidad y la excelencia
              en el servicio, se encuentran con la alta tecnología.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <img src={Cuadro4} alt="Cuadro4" />
          <div className="cost dark-text">Puebla - Pue</div>
          <div className="overlay">
            <h1>Centro comercial explanada puebla</h1>
            <p>
              Más de 20 años de experiencia, nuestras amplia cobertura y los
              más altos estándares de calidad son en lo que los expertos confían.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <img src={Cuadro5} alt="Cuadro5" />
          <div className="cost dark-text">Veracruz - Ver</div>
          <div className="overlay">
            <h1>Ampliación puerto de veracruz</h1>
            <p>
              Contamos con el mejor equipo de profesionales que te acompañan
              en todo momento.
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default SwiperComponent;
